import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://5b6d012e25e8429787fe3ddfad09f987@o490824.ingest.sentry.io/5555396",
  integrations: [new Integrations.BrowserTracing()],
  denyUrls: ["http://localhost:8080/", "https://app.kurvan.local/"],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
